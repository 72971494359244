// extracted by mini-css-extract-plugin
export var container = "timeline-module--container--rSpgW";
export var line = "timeline-module--line--wuKG3";
export var lineClosed = "timeline-module--lineClosed--ysHu2";
export var fade = "timeline-module--fade--PI3yZ";
export var upcomingFadein = "timeline-module--upcomingFadein--NzxF5";
export var openFadein = "timeline-module--openFadein--8ObaV";
export var upcomingFadeout = "timeline-module--upcomingFadeout--gJx-x";
export var openFadeout = "timeline-module--openFadeout--e4hwO";
export var closedFadein = "timeline-module--closedFadein--xzKll";
export var closedFadeout = "timeline-module--closedFadeout--Ua4jx";