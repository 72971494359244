// extracted by mini-css-extract-plugin
export var container = "infobox-module--container--w8P96";
export var info = "infobox-module--info--hPamv";
export var timeDetails = "infobox-module--timeDetails--SFJpX";
export var active = "infobox-module--active--Kqqch";
export var title = "infobox-module--title--5AUT8";
export var content = "infobox-module--content--SP0Az";
export var button = "infobox-module--button--pDgfk";
export var stages = "infobox-module--stages--K-XeL";
export var stage = "infobox-module--stage--ReEqw";
export var event = "infobox-module--event--1CqBy";