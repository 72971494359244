// extracted by mini-css-extract-plugin
export var item = "item-module--item--Ewphz";
export var month = "item-module--month--6IwO5";
export var deco = "item-module--deco--f2l4Y";
export var circle = "item-module--circle--i40iN";
export var line = "item-module--line--PvhGx";
export var subtitle = "item-module--subtitle--IPJyl";
export var content = "item-module--content--o398D";
export var year = "item-module--year--HJUt3";
export var fadein = "item-module--fadein--vcj4k";
export var fadeout = "item-module--fadeout--Qepf1";