const TEXTS: Record<string, any> = {
  zh: {
    title: "人物介紹",
    toOpensea: "至 OpenSea 查看",
    intro:
      "Traveloggers 有 6 個基礎人物，分別是  Gena、Lesya、Sasha、Valya、Yury、Zhenya。我們根據創作者的價值取向" +
      "、哲學主張、思考方式、創作個性等維度，提取關鍵元素組合，創造了 1500 個 Traveloggers 的化身，每一個都獨一無二。",
    sasha: "Sasha 薩沙",
    valya: "Valya 瓦拉",
    zhenya: "Zhenya 振亞",
    gena: "Gena 根那",
    lesya: "Lesya 列峽",
    yury: "Yury 尤里",
    example: "一個人物＋兩個配件",
    value: "價值立場",
    perspective: "哲學主張",
    thinking: "思考與行為特徵",
    personality: "氣質與性格",
    char1_1_desc: "保守主義",
    char1_2_desc: "價值多元",
    char1_3_desc: "個人主義",
    char1_4_desc: "集體主義",
    char1_5_desc: "女權主義",
    char1_6_desc: "性別友善",
    char1_7_desc: "反戰主義/反核主義",
    char1_8_desc: "無政府主義",
    char1_9_desc: "自由主義",
    char1_10_desc: "民族主義",
    char1_11_desc: "世界主義",
    char1_12_desc: "本土主義",
    char2_1_desc: "理性主義",
    char2_2_desc: "存在主義",
    char2_3_desc: "資本主義",
    char2_4_desc: "馬克思主義",
    char2_5_desc: "懷疑主義",
    char2_6_desc: "悲觀主義",
    char2_7_desc: "樂觀主義",
    char2_8_desc: "犬儒主義",
    char2_9_desc: "伊比鳩魯主義",
    char2_10_desc: "浪漫主義",
    char2_11_desc: "實用主義",
    char3_1_desc: "外向",
    char3_2_desc: "內向",
    char3_3_desc: "想像力",
    char3_4_desc: "思辨",
    char3_5_desc: "感知",
    char3_6_desc: "務實型",
    char3_7_desc: "冒險家",
    char3_8_desc: "積極公民",
    char3_9_desc: "實踐家",
    char3_10_desc: "旅行者",
    char3_11_desc: "旁觀者",
    char4_1_desc: "自律",
    char4_2_desc: "瀟灑",
    char4_3_desc: "反叛",
    char4_4_desc: "合群",
    char4_5_desc: "進取",
    char4_6_desc: "淡泊",
    char4_7_desc: "迷茫",
    char4_8_desc: "堅定",
  },
  en: {
    title: "Introducing Characters",
    toOpensea: "View all on OpenSea",
    intro:
      "Traveloggers have six basic characters, namely, Gena, Lesya, Sasha, Valya, Yury, and Zhenya. " +
      "1500 avatars were generated based on the combination of different accessories: value position, " +
      "philosophical standpoint, way of thinking, creative habits, personality, etc. Each Traveloggers is " +
      "unique with its own creator attributes.",
    sasha: "Sasha",
    valya: "Valya",
    zhenya: "Zhenya",
    gena: "Gena",
    lesya: "Lesya",
    yury: "Yury",
    example: "1 Character + 2 Accessories",
    value: "Values",
    perspective: "Philosophical Perspectives",
    thinking: "Ways of Thinking",
    personality: "Temperaments and Personality Traits",
    char1_1_desc: "Conservative",
    char1_2_desc: "Pluralistic",
    char1_3_desc: "Individualistic",
    char1_4_desc: "Collectivistic",
    char1_5_desc: "Feminist",
    char1_6_desc: "LGBTQ friendly",
    char1_7_desc: "Pacifists / Antinuclearists",
    char1_8_desc: "Anarchist",
    char1_9_desc: "Liberal",
    char1_10_desc: "Nationalistic",
    char1_11_desc: "Cosmopolitan",
    char1_12_desc: "Localist",
    char2_1_desc: "Rational",
    char2_2_desc: "Existentialist",
    char2_3_desc: "Capitalistic",
    char2_4_desc: "Marxist",
    char2_5_desc: "Skeptical",
    char2_6_desc: "Pessimistic",
    char2_7_desc: "Optimistic",
    char2_8_desc: "Cynical",
    char2_9_desc: "Epicurean",
    char2_10_desc: "Romantic",
    char2_11_desc: "Pragmatist",
    char3_1_desc: "Extroverted",
    char3_2_desc: "Introverted",
    char3_3_desc: "Imaginative",
    char3_4_desc: "Analytical",
    char3_5_desc: "Compassionate",
    char3_6_desc: "Down-to-earth",
    char3_7_desc: "Adventurous",
    char3_8_desc: "Enthusiastic",
    char3_9_desc: "Practical",
    char3_10_desc: "Exploring",
    char3_11_desc: "Observant",
    char4_1_desc: "Self-disciplined",
    char4_2_desc: "Chic",
    char4_3_desc: "Rebellious",
    char4_4_desc: "Sociable",
    char4_5_desc: "Hard-working",
    char4_6_desc: "Nonchalant",
    char4_7_desc: "Confused",
    char4_8_desc: "Determined",
  },
}

export default TEXTS
